.wrapper {
   position: fixed;
   top: 0;
   transform: translateX(50%);
   right: 50%;
   z-index: 1000;
   padding: 16px;
   min-width: auto;
   height: fit-content;

   @media screen and (min-width: 768px) {
      position: fixed;
      top: initial;
      bottom: 0;
      padding: 24px;
      width: fit-content;
   }
}

.toast {
   border-radius: 6px;
   display: flex;
   align-items: center;
   gap: 8px;
   border-radius: 100px;
   padding: 16px;
   height: 44px;

   svg {
      width: 20px;
      height: 20px;
      flex-shrink: 0;
      color: var(--Neutral-Black-Black);
   }
}

.content {
   width: 100%;

   .title {
      color: var(--Neutral-Black-Black);
      font-size: 13px;
      font-weight: 700;
   }

   .message {
      margin-top: 4px;
      color: var(--Neutral-White-White);
      font-size: 13px;
      font-weight: 500;
   }
}
.m-radio {
   display: inline-flex;
   align-items: center;
   position: relative;
   height: 100%;

   & input {
      position: absolute;
      z-index: 1;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      cursor: pointer;
   }

   & input:checked~span {
      border-color: var(--Brand-Golden-500);
      background: var(--Brand-Golden-500);

      &::after {
         display: flex;
         align-items: center;
         justify-content: center;
      }
   }

   span {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      border: 2px solid var(--Neutral-Slate-300);
      background: #fff;
      border-radius: 20px;
      flex-shrink: 0;

      &::after {
         content: '';
         display: none;
         width: 10px;
         height: 10px;
         border-radius: 50%;
         background-color: var(--Neutral-Black-Black);
      }
   }

   label {
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 160%;
      margin-left: 12px;
      margin-bottom: 0;
   }

   &--disabled {
      span {
         opacity: 0.4;
      }

      label {
         color: var(--Neutral-Slate-500);
      }
   }
}
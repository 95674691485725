.m-badge {
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 12px 16px;
   gap: 4px;
   height: 44px;
   border-radius: 32px;
   width: fit-content;
   transition: width 1s ease;
   max-width: 100%;
   p {
      font-size: 14px;
      font-weight: 700;
      color: var(--Neutral-Black-Black);
      text-wrap: nowrap;

      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
   }
   button {
      display: flex;
      align-items: center;
      svg {
         width: 16px;
         height: 16px;
         color: var(--Neutral-Black-Alpha-40);
      }
   }
   &--sm {
      padding: 5px 8px;
      height: 24px;
      gap: 2px;
      transition: all 0.2s ease-in;
      p {
         font-size: 10px;
         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
         max-width: 110px;
      }
      button {
         svg {
            width: 12px;
            height: 12px;
         }
      }
   }
   &--smHeight{
      height: 12px;
      width: 32px;
   }
}

.m-badge-client{
   transition: all 0.2s ease-in;
}
.m-badge-client--active {
   transition: all 0.2s ease-in;
   width: 32px;
}

.m-blocknote {
   width: 100%;

   .bn-container[data-theming-css-demo] .bn-editor * {
      overflow: auto;
      height: 100%;
      font-family: "DM Sans", sans-serif;
      font-size: 14px;
      color: var(--Neutral-Black-Black);
      a {
         cursor: pointer;
         color: var(--Semantic-Sky-600);
      }
      ::placeholder {
         opacity: 1;
      }
      ::selection {
         background: var(--Brand-Golden-200, #f8e7ab);
      }
   }

   .bn-container[data-theming-css-demo] .bn-editor {
      padding: 0;
      background-color: transparent;
   }

   .bn-container[data-theming-css-demo] .bn-inline-content {
      font-size: 14px;
      font-family: "DM Sans", sans-serif;
   }

   .bn-container[data-theming-css-demo]
      .bn-block-content[data-is-empty-and-focused]
      .bn-inline-content:has(> .ProseMirror-trailingBreak:only-child)::before {
      content: 'Ajoutez des notes ici';
      font-style: normal;
      font-family: "DM Sans", sans-serif;
      font-size: 14px;
      color: var(--Neutral-Slate-400);
   }

   .bn-block-content[data-content-type='bulletListItem'] {
      gap: 12px;
   }

   .bn-container[data-theming-css-demo] .bn-toolbar {
      background: var(--Neutral-Slate-950, #020617);
   }

   .bn-container[data-theming-css-demo] .bn-toolbar * {
      background: var(--Neutral-Slate-950, #020617);
      color: #fff;
   }
   &--small {
      .bn-container[data-theming-css-demo] .bn-editor * {
         font-size: 13px;
         font-style: normal;
         font-weight: 400;
      }
   }
}

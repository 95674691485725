.m-dropdown-item {
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding: 8px;
   border-radius: 12px;
   height: 40px;
   width: 100%;
   text-align: left;
   cursor: pointer;

   &:hover {
      background-color: var(--Neutral-Slate-100);
   }

   &:focus {
      background-color: var(--Neutral-Slate-100);
   }

   &__left {
      display: flex;
      align-items: center;
      gap: 8px;
      color: var(--Neutral-Black-Black);
      width: 100%;
      svg {
         width: 20px;
         height: 20px;
         flex-shrink: 0;
         color: var(--Neutral-Black-Black) !important;
      }

      p {
         font-size: 14px;
         font-weight: 700;
         white-space: nowrap;
         text-overflow: ellipsis;
         overflow: hidden;
      }
   }

   &__avatar {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--Neutral-Slate-100);
      overflow: hidden;

      &__placeholder {
         padding: 7px;

         svg {
            width: 10px !important;
            height: 10px !important;
         }
      }
   }

   svg {
      width: 20px;
      height: 20px;
      flex-shrink: 0;
      color: var(--Neutral-Slate-400);
   }
}

.m-tabs {
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 8px;
   width: fit-content;
   border-bottom: 2px solid transparent;
   padding: 12px;
   @media screen and (min-width: 768px) {
      border-bottom: 2px solid transparent;
   }
   &__label {
      color: var(--Neutral-Black-Alpha-60);
      font-weight: 700;
      font-size: 14px;
      @media screen and (min-width: 768px) {
         font-size: 16px;
      }
   }
   p {
      transition: all 0.1s ease-in;
      color: var(--Neutral-Black-Alpha-60);
      font-weight: 700;
   }
   &:hover{
      p{
         color: var(--Neutral-Black-Alpha-80);
      }
   }
   svg {
      color: var(--Neutral-Black-Black);
      width: 20px;
      height: 20px;
   }
   &--active {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      width: fit-content;
      border-bottom: 2px solid var(--Neutral-Black-Black);
      padding: 12px;
      @media screen and (min-width: 768px) {
         border-bottom: 2px solid var(--Neutral-Black-Black);
      }
      &__label {
         color: var(--Neutral-Black-Black);
         font-weight: 700;
         font-size: 14px;
         @media screen and (min-width: 768px) {
            font-size: 16px;
         }
      }
      p {
         color: var(--Neutral-Black-Black);
         font-weight: 700;
      }
      svg {
         color: var(--Neutral-Black-Black);
         width: 20px;
         height: 20px;
      }
   }
}

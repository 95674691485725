.m-switch {
   position: relative;
   display: block;
   width: 56px;
   height: 32px;
   input {
      opacity: 0;
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 1;
      cursor: pointer;
   }
   input:checked + span {
      background-color: var(--Semantic-Lime-500);
   }
   input:checked + span::before {
      transform: translateX(23px);
   }
   span {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: var(--Neutral-Slate-200);

      transition: 0.4s;
      border-radius: 30px;
      &::before {
         position: absolute;
         content: '';
         height: 24px;
         width: 24px;
         left: 4px;
         bottom: 3px;
         background-color: #fff;
         transition: 0.4s;
         border-radius: 50%;
         top: 50%;
         margin-top: -12px;
      }
   }
   &--md {
      width: 40px;
      height: 24px;
      span {
         &:before {
            height: 16px;
            width: 16px;
            left: 4px;
            bottom: 3px;
            margin-top: -8px;
         }
      }
      input:checked + span::before {
         transform: translateX(15px);
      }
   }
}

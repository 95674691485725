.m-button {
   position: relative;
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 8px;
   border: none;
   border-radius: 32px;
   padding: 12px 16px;
   height: 44px;
   font-size: 14px;
   font-weight: 700;
   text-wrap: nowrap;

   span {
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--Neutral-Black-Alpha-40);
      z-index: 1;
   }

   &::after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 100px;
      transition: all 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
      z-index: 0;
      background-color: inherit;
   }

   &:hover {
      &:after {
         @media screen and (min-width: 768px) {
            transform: scale(1.05);
         }
      }
   }

   &:disabled {
      opacity: 0.4;
   }

   &--round {
      border-radius: 50%;
      width: 44px;
      height: 44px;

      &::after {
         width: 44px;
         height: 44px;
         border-radius: 50%;
      }

      svg {
         width: 20px;
         height: 20px;
         flex-shrink: 0;
      }
   }

   &--round--md {
      border-radius: 50%;
      width: 40px;
      height: 40px;

      svg {
         width: 20px;
         height: 20px;
         flex-shrink: 0;
      }
   }

   svg {
      width: 20px;
      height: 20px;
      flex-shrink: 0;
      z-index: 1;
   }

   &--white {
      background-color: var(--Neutral-White-White);
      color: var(--Neutral-Black-Black);

      span {
         display: flex;
         align-items: center;
         justify-content: center;
         color: var(--Neutral-Black-Black);
      }
   }

   &--primary {
      background-color: var(--Neutral-Black-Black);
      color: var(--Neutral-White-White);

      span {
         display: flex;
         align-items: center;
         justify-content: center;
         color: var(--Neutral-White-alpha-40);
      }
   }

   &--secondary {
      background-color: var(--Neutral-Slate-200);
      color: var(--Neutral-Black-Black);
      &:hover{
         background-color: var(--Neutral-Slate-300);

      }
      span {
         display: flex;
         align-items: center;
         justify-content: center;
         color: var(--Neutral-Black-Black);
      }

      svg {
         color: var(--Neutral-Black-Black);
         width: 20px;
         height: 20px;
         flex-shrink: 0;
      }
   }

   &--tertiary {
      background-color: transparent;
      color: var(--Neutral-Black-Black);
      border: 2px solid var(--Neutral-Slate-200);
      transition:all 0.1s ease-in;
      span {
         display: flex;
         align-items: center;
         justify-content: center;
         color: var(--Neutral-Black-Black);
      }

      svg {
         color: var(--Neutral-Black-Black);
         width: 20px;
         height: 20px;
         flex-shrink: 0;
      }
      &:hover{
         border-color:var(--Neutral-Slate-300)
      }
   }

   &--transparent {
      background-color: transparent;
      color: var(--Neutral-Black-Black);
   }

   &--alpha-White {
      background-color: var(--Neutral-White-Alpha-20);
      color: var(--Neutral-White-White);
   }

   &--alpha-Black {
      background-color: var(--Neutral-Black-Alpha-20);

      span {
         color: var(--Neutral-Black-Black);
      }

      svg {
         color: var(--Neutral-Black-Black);
      }

      &:hover {
         transition: all 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
         background-color: var(--Neutral-Black-Alpha-30);

         &::after {
            @media screen and (min-width: 768px) {
               transform: scale(1);
            }
         }
      }

      &::after {
         display: none;
      }
   }

   &--alpha-White {
      background-color: var(--Neutral-White-Alpha-20);

      span {
         color: var(--Neutral-White-White);
      }

      svg {
         color: var(--Neutral-White-White);
      }

      &:hover {
         transition: all 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
         background-color: var(--Neutral-White-Alpha-30);

         &::after {
            @media screen and (min-width: 768px) {
               transform: scale(1);
            }
         }
      }

      &::after {
         display: none;
      }
   }

   &--play {
      background-color: var(--Neutral-Black-Black);
      color: var(--Neutral-White-White);
      width: 32px;
      height: 32px;

      svg {
         width: 16px;
         height: 16px;
         flex-shrink: 0;
      }
   }

   &--play-white {
      background-color: var(--Neutral-White-White);
      color: var(--Neutral-Black-Black);

      svg {
         width: 16px;
         height: 16px;
         flex-shrink: 0;
      }
   }

   &--play-sm {
      background-color: var(--Neutral-Black-Black);
      color: var(--Neutral-White-White);
      height: 24px;
      width: 24px;
      padding: 0;

      svg {
         width: 12px;
         height: 12px;
         flex-shrink: 0;
      }
   }

   &--play-lg {
      background-color: var(--Neutral-Black-Black);
      color: var(--Neutral-White-White);
      height: 44px;
      width: 44px;
      padding: 0;

      svg {
         width: 20px;
         height: 20px;
         flex-shrink: 0;
      }
   }
}
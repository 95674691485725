.main {
   background-color: rgba(0, 0, 0, 0.5);
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   z-index: 999999;
   display: flex;
   align-items: flex-end;
   justify-content: center;
   padding: 30px 0 0;
   overflow-y: auto;
   opacity: 0; 
   transition: opacity 0.1s ease;
   pointer-events: none; 

   &.visible {
      opacity: 1;
      pointer-events: auto;
   }

   &.hidden {
      opacity: 0;
      pointer-events: none;
   }

   @media (min-width: 768px) {
      align-items: center;
      padding: 30px;
   }

   @media (min-width: 1024px) {
      align-items: center;
      padding: 40px;
   }
}

   .box {
      background-color: #fff;
      border-radius: 32px 32px 0px 0px;
      width: 100%;
      position: relative;
      overflow: auto;
      max-height: 90vh;
      padding: 32px;
      transform: translateY(-100%); 
      opacity: 0; 
      transition: transform 0.3s ease, opacity 0.3s ease; 

      @media (min-width: 768px) {
         border-radius: 10px;
         max-width: 520px;
         max-height: 90vh;
      }
   }
   .visible .box {
      transform: translateY(0%);
      opacity: 1;
   }

   .hidden .box {
      transform: translateY(-100%); 
      opacity: 0;
   }

.boxHeader {
   position: relative;
   display: flex;
   align-items: center;
   justify-content: space-between;
   height: 44px;
   border-bottom: 1px solid var(--Neutral-Gray-200);

   @media screen and (min-width: 768px) {
      justify-content: space-between;
   }

   .title {
      color: var(--Neutral-Black-Black);
      font-size: 20px;
      font-weight: 700;
   }

   .close {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      width: 44px;
      height: 44px;
      border-radius: 32px;
      justify-content: center;
      align-items: center;
      background: var(--Neutral-Slate-200);

      @media screen and (min-width: 768px) {
         top: initial;
         right: initial;
         position: relative;
      }

      &::after {
         content: '';
         display: block;
         width: 100%;
         height: 100%;
         position: absolute;
         top: 0;
         left: 0;
         border-radius: 100px;
         transition: all 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
         z-index: 0;
         background-color: inherit;
      }

      &:hover {
         &::after {
            @media screen and (min-width: 768px) {
               transform: scale(1.1);
            }
         }
      }

      svg {
         width: 20px;
         height: 20px;
         flex-shrink: 0;
         z-index: 1;
         color: var(--Neutral-Black-Black);
      }
   }
}


%all {
   margin: 0;
   padding: 0;
   -webkit-box-sizing: border-box;
   -moz-box-sizing: border-box;
   box-sizing: border-box;
   list-style: none;
   -webkit-print-color-adjust: exact;
   print-color-adjust: exact;
}

*,
*:after,
*:before {
   @extend %all;
}

html {
   margin: 0;
   padding: 0;
}

h1,
h2,
h3,
h4 {
   font-weight: inherit;
}

h1 {
   font-size: 30px;
   font-weight: 700;
}

body {
   -webkit-font-smoothing: antialiased;
   text-rendering: optimizeLegibility;
   overflow-x: hidden;
   background-color: var(--Neutral-Slate-100);
   font-family: var(--font-dmSans);
   color: black;
   font-display: swap;
   min-height: 100vh;
   max-width: 100vw;
}

.unstyled::-webkit-inner-spin-button {
   height: 100%;
}

a {
   text-decoration: none;
}

ul {
   list-style: none;
}

img {
   max-width: 100%;
   height: auto;
   vertical-align: middle;
   -webkit-box-sizing: border-box;
   -moz-box-sizing: border-box;
   box-sizing: border-box;
}

textarea,
input,
button,
select {
   -webkit-appearance: none;
   outline: none;
   background: none;
   border: none;
   font-family: var(--font-dmSans);
}

button {
   cursor: pointer;
   display: block;
   user-select: none;
   font-family: var(--font-dmSans);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
}
input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
   display: none;
   -webkit-appearance: none;
}

input[type='time']::-webkit-inner-spin-button,
input[type='time']::-webkit-calendar-picker-indicator {
   display: none;
   -webkit-appearance: none;
}

input[type='number'] {
   -moz-appearance: textfield;
}

input[type='range']::-webkit-slider-runnable-track {
   background: var(--Neutral-Gray-200);
   height: 3px;
}

input[type='range']::-moz-range-track {
   background: var(--Neutral-Gray-200);
   height: 3px;
}

input[type='range']::-webkit-slider-thumb {
   -webkit-appearance: none;
   appearance: none;
   margin-top: -4px;
   border-radius: 10px;
   background-color: var(--Brand-Mandy-400);
   height: 10px;
   width: 10px;
}

svg {
   line-height: 0;
}

.container {
   max-width: 1200px;
   margin: 0 auto;
   padding: 24px 12px;

   @media screen and (min-width: 768px) {
      padding: 40px 24px;
   }
}

.general_layout {
   padding: 20px 16px 60px 16px;
   @media screen and (min-width: 1025px) {
      padding: 32px 32px 32px 112px;
   }
}

.general_layout_table {
   height: 100vh;
   padding: 0 0 0 16px;
   @media screen and (min-width: 1025px) {
      padding: 0 0 0 112px;
   }
}

.calendar {
   width: 100%;
}

.sr-only {
   clip: rect(0px, 0px, 0px, 0px);
   height: 1px;
   line-height: 0;
   text-indent: -6.25rem;
   width: 1px;
   white-space: nowrap;
   z-index: -1;
   position: absolute;
   border-width: 0px;
   border-style: initial;
   border-color: initial;
   border-image: initial;
   margin: -1px;
   outline: none;
   overflow: hidden;
   padding: 0px;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
   scrollbar-width: auto;
   scrollbar-color: transparent;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
   width: 8px;
   height: 8px;
}

*::-webkit-scrollbar-track {
   background: transparent;
}

*::-webkit-scrollbar-thumb {
   background-color: #2f2f2f3b;
   border-radius: 12px;
}

[contenteditable] {
   outline: 0px solid transparent;
}

[contenteditable='true']:empty:before {
   content: attr(placeholder);
   pointer-events: none;
   display: block; /* For Firefox */
}

.SingleDatePickerInputContainer {
   border: 2px solid var(--Neutral-Slate-200, #e2e8f0) !important;
}
.SingleDatePickerSelected:hover {
   background-color: var(--Neutral-Black-Alpha-80) !important;
}

.generalLoading {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   z-index: 10;
   display: flex;
   align-items: center;
   justify-content: center;

   svg {
      width: 150px;
      height: 150px;
      flex-shrink: 0;
   }
}

p {
   line-height: 140%;
}

//loading for server side rendering
.loadingContainer {
   display: flex;
   align-items: center;
   justify-content: center;
   min-height: 100vh;
}

.spinner {
   width: 50px;
   height: 50px;
   border: 3px solid #f3f3f3;
   border-top: 3px solid #3498db;
   border-radius: 50%;
   animation: spin 1s linear infinite;
}

@keyframes spin {
   0% {
      transform: rotate(0deg);
   }
   100% {
      transform: rotate(360deg);
   }
}

.m-checkbox {
   display: inline-flex;
   align-items: center;
   position: relative;
   width: 100%;
   height: 100%;
   & input {
      position: absolute;
      z-index: 1;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      cursor: pointer;
   }
   & input:checked ~ span {
      border-color: var(--Brand-Golden-500);
      background: var(--Brand-Golden-500);
      &::after {
         display: flex;
         align-items: center;
         justify-content: center;
      }
   }
   span {
      position: relative;
      display: block;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      border: 2px solid var(--Neutral-Slate-300);
      background: #fff;
      border-radius: 3px;
      flex-shrink: 0;
      &::after {
         content: '';
         position: absolute;
         display: none;
         left: 6px;
         top: 1px;
         width: 6px;
         height: 11px;
         border: solid var(--Neutral-Black-Black);
         border-width: 0 2px 2px 0;
         transform: rotate(45deg);
         border-radius: 1px;
      }
   }
   label {
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 160%;
      margin-left: 12px;
      margin-bottom: 0;
   }
   &--disabled {
      span {
         opacity: 0.4;
      }
      label {
         color: var(--Neutral-Slate-500);
      }
   }
   &--auto {
      width: auto;
   }
}
